@charset "ISO-8859-1";

html {
  scroll-behavior: smooth !important;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
}
.wrapper {
  margin: 0 auto;
  width: 100%;
}

.homePage {
  position: absolute !important;
  top: 0% !important;
}

.appBar {
  background-color: transparent;
}

.backdrop-gradient {
  background: #ffffff !important;
  opacity: 0.8 !important;
}

.backdrop-gradient2 {
  background: #ffffff !important;
  opacity: 0.5 !important;
}

.page-backdrop {
  opacity: 1 !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  filter: saturate(80%) !important;
}

.footer {
  position: absolute !important;
  bottom: 1% !important;
}

.contact-animated {
  border-radius: 50% !important;
  background-color: #e89b00 !important;
  opacity: 0.7 !important;
}

.contact-animated:hover .contact-icon {
  content: "";
  transition: 0.2s linear !important;
  border-radius: 50% !important;
  font-size: 40px !important;
  background-color: #e89b00 !important;
}

.scrollTop-animated {
  border-radius: 50% !important;
  background-color: #e89b00 !important;
  opacity: 0.7 !important;
}

.scrollTop-animated:hover .scrollTop-icon {
  content: "";
  transition: 0.2s linear !important;
  border-radius: 50% !important;
  font-size: 40px !important;
  background-color: #e89b00 !important;
}

.card-border-top {
  border-top: 7px solid;
}

.close-text-animate:hover .icon-animate {
  animation: rotate 0.2s linear;
  color: #e89b00 !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.close-text-animate:hover {
  color: #e89b00 !important;
}

.link:hover {
  color: #e89b00 !important;
  font-size: 22px !important;
  transition: ease-in 0.1s !important;
  cursor: pointer !important;
}

.link2:hover {
  color: #e89b00 !important;
  font-size: 14px !important;
  transition: ease-in 0.1s !important;
  cursor: pointer !important;
}

.site-text {
  font-family: "Noto Sans", sans-serif !important;
}

.other-text {
  font-family: "Noto Sans", sans-serif !important;
}

.mini-link:hover {
  text-decoration: #e89b00 underline !important;
}

.img-container {
  position: absolute !important;
  margin-top: -130px !important;
}

.menu-icon:hover .menu-icon-child {
  color: #5c5c5c !important;
  cursor: pointer !important;
}

.site-logo:hover p {
  color: #5c5c5c !important;
  cursor: pointer !important;
}

.site-log:hover img {
  filter: grayscale(80%) !important;
}

.contact-link {
  cursor: pointer !important;
}

.contact-link:hover {
  text-decoration: #e89b00 underline !important;
}

.next-link {
  cursor: pointer !important;
}

.next-link:hover {
  text-decoration: #e89b00 underline !important;
}

.image-fadeout {
  opacity: 0.4 !important;
  transition-delay: 0.1s !important;
}

.project-container {
  position: absolute !important;
}

.card-container {
  position: relative !important;
  cursor: pointer !important;
}

.card-text {
  position: absolute !important;
  top: 0% !important;
}

.card-container:hover .img-card {
  opacity: 0.9 !important;
  cursor: pointer !important;
}

.card-container:hover .card-text {
  background: linear-gradient(
    90deg,
    rgb(8, 15, 23) 0%,
    rgb(14, 18, 23) 39%,
    rgb(14, 35, 50) 100%
  );
  width: auto !important;
  height: 100% !important;
  opacity: 0.8 !important;
  top: 0% !important;
}

.card-container:hover .view-project {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

.card-container:hover .card-text-child {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

.card-container:hover .card-text-title {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

@keyframes scale {
  0% {
    transform: scaleY(0%);
  }

  100% {
    transform: scaleY(100%);
  }
}

.client-icon-button:hover {
  background-color: #e89b00 !important;
  opacity: 0.9 !important;
}

.client-icon-button:hover .client-text {
  color: #ffffff !important;
}

.footer-link:hover {
  color: #e89b00 !important;
  transition: 0.2s ease-in !important;
}

.image-effect-main {
  -webkit-filter: blur(10px) grayscale(80%);
  filter: blur(10px) grayscale(80%);
}

.img-container {
  position: relative !important;
  cursor: pointer !important;
}

.img-overlay-prev-icon {
  position: absolute !important;
  top: 40% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  justify-content: flex-start !important;
}

.img-hover-zoom:hover {
  transform: scale(1.1);
  cursor: pointer !important;
  transition: 0.3s ease !important;
}

.link-text:hover {
  color: #e89b00 !important;
  cursor: pointer !important;
  transition: 0.3s ease !important;
}

.img-container:hover .img-overlay-prev-icon,
.img-container:hover .img-overlay-next-icon {
  display: flex !important;
  transition: ease-in 1s !important;
  opacity: 0.5 !important;
}

.img-overlay-next-icon {
  position: absolute !important;
  top: 40% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  justify-content: flex-end !important;
}

.avatar-overlay {
  position: absolute !important;
  top: 20% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  text-align: center !important;
  align-items: center !important;
  left: inherit;
}

.img-effect {
  -webkit-filter: grayscale(90%) !important;
  filter: grayscale(90%) !important;
}

/*-----------------------------*/

/*  home page image slider*/
.img-container {
  position: relative !important;
}
.img-overlay-video-icon {
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-text {
  position: absolute !important;
  color: #fff !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-text-secondary {
  position: absolute !important;
  color: #fff !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-description {
  position: absolute !important;
  color: #fff !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-button {
  position: absolute !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-prev-icon {
  position: absolute !important;
  top: 45% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.img-overlay-next-icon {
  position: absolute !important;
  top: 45% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.img-overlay-prev:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.img-overlay-next:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/*------------------*/

.short-text {
  text-overflow: ellipsis !important;
}

/**  CSS STYLE FOR IMAGE SLIDE SHOWS  **/

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px !important;
  position: relative !important;
  margin: auto !important;
}

/* Hide the images by default */

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer !important;
  position: absolute !important;
  top: 40% !important;
  width: auto !important;
  margin-top: -22px !important;
  padding: 16px !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 18px !important;
  transition: 0.6s ease !important;
  border-radius: 0 3px 3px 0 !important;
  user-select: none !important;
}

/* Position the "next button" to the right */
.next {
  right: 0 !important;
  border-radius: 3px 0 0 2px !important;
}

/* On hover, add a black b4ckground color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer !important;
  height: 15px !important;
  width: 15px !important;
  margin: 0 2px !important;
  background-color: #bbb !important;
  border-radius: 50% !important;
  display: inline-block !important;
  transition: background-color 0.6s ease !important;
}

.active,
.dot:hover {
  background-color: #0099cc !important;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.secondary-btn-hover:hover {
  background-color: #ea6c11 !important;
  color: #ffffff !important;
}

.btn-hover {
  width: 200px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center !important;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0 !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.btn-hover:focus {
  outline: none !important;
}

.btn-hover.color-1 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75) !important;
}
.btn-hover.color-2 {
  background-image: linear-gradient(
    to right,
    #f5ce62,
    #e48e03,
    #fa7199,
    #e87d19
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75) !important;
}
.btn-hover.color-3 {
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75) !important;
}
.btn-hover.color-4 {
  background-image: linear-gradient(
    to right,
    #fca714,
    #ff9a44,
    #ef9d43,
    #e75516
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75) !important;
}
.btn-hover.color-5 {
  background-image: linear-gradient(
    to right,
    #0ba360,
    #3cba92,
    #30dd8a,
    #2bb673
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75) !important;
}
.btn-hover.color-6 {
  background-image: linear-gradient(
    to right,
    #009245,
    #fcee21,
    #00a8c5,
    #d9e021
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75) !important;
}
.btn-hover.color-7 {
  background-image: linear-gradient(
    to right,
    #6253e1,
    #852d91,
    #a3a1ff,
    #f24645
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75) !important;
}
.btn-hover.color-8 {
  background-image: linear-gradient(
    to right,
    #29323c,
    #485563,
    #2b5876,
    #4e4376
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75) !important;
}
.btn-hover.color-9 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75) !important;
}
.btn-hover.color-10 {
  background-image: linear-gradient(
    to right,
    #ed6ea0,
    #ec8c69,
    #f7186a,
    #fbb03b
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75) !important;
}
.btn-hover.color-11 {
  background-image: linear-gradient(
    to right,
    #eb3941,
    #f15e64,
    #e14e53,
    #e2373f
  ) !important;
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4) !important;
}

/** **/
.img-effect-secondary {
  -webkit-filter: blur(1px) grayscale(10%) saturate(1.4);
  filter: saturate(1.9);
  transition: 0.5s ease;
}

.image-effect-main {
  -webkit-filter: blur(10px) grayscale(80%);
  filter: blur(10px) grayscale(80%);
}

.img-container {
  position: relative;
}
.img-overlay-video-icon {
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-text {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.8); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-text-secondary {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 90%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-prev-icon {
  position: absolute !important;
  top: 40% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.img-hover-zoom:hover {
  transform: scale(1.05) !important;
  cursor: pointer !important;
  transition: 0.3s ease !important;
}

.search-input-hover:hover {
  background-color: rgb(219, 219, 219) !important;
}

.swiper {
  width: 100% !important;
  height: 100% !important;
}

.swiper-slide {
  text-align: center !important;
  font-size: 18px !important;
  background: rgb(82, 53, 53) !important;

  /* Center slide text vertically */
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center !important;
  align-items: center !important;
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.img-hover-zoom {
  height: 237px !important; /* Modify this according to your need */
  overflow: hidden !important; /* Removing this will break the effects */
}

/* Zoom-n-rotate Container */
.img-hover-zoom--zoom-n-rotate .card-img {
  transition: transform 0.5s ease !important;
}

/* The Transformation */
.img-hover-zoom--zoom-n-rotate:hover .card-img {
  transform: scale(1.2) !important;
}

#some-shape {
  display: block;
  background-color: #26a69a;
  border-radius: 80% 30% 30% 80% / 60% 60% 90% 50%;
}

.cone {
  border-left: 90px solid transparent;
  border-right: 90px solid transparent;
  border-top: 80px solid #26a69a;
  border-radius: 50%;
  padding: 1%;
}

.bg-news {
  color: #fff;
  background: -webkit-linear-gradient(110deg, #110740 40%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #080320 70%, #0c052e 70%) !important;
  background: -o-linear-gradient(110deg, #110740 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #080320 70%, #0c052e 70%) !important;
  background: -moz-linear-gradient(110deg, #110740 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #080320 70%, #0c052e 70%) !important;
  background: linear-gradient(110deg, #110740 40%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #080320 70%, #0c052e 70%) !important;
}

.card-category0 {
  background-color: rgba(156, 39, 176, 0.1) !important;
  border: 1px solid rgba(156, 39, 176, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category0:hover {
  background-color: #ba68c8 !important;
  color: #fff !important;
}

.card-category0:hover > .card-category-icon0 {
  color: #fff !important;
}

.card-category1 {
  background-color: rgba(255, 235, 59, 0.2) !important;
  border: 1px solid rgba(255, 235, 59, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category1:hover {
  background-color: #ff8a65 !important;
  color: #fff !important;
}
.card-category1:hover > .card-category-icon1 {
  color: #fff !important;
}

.card-category2 {
  background-color: rgba(0, 188, 212, 0.1) !important;
  border: 1px solid rgba(0, 188, 212, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category2:hover {
  background-color: #4dd0e1 !important;
  color: #fff !important;
}
.card-category2:hover > .card-category-icon2 {
  color: #fff !important;
}

.card-category3 {
  background-color: rgba(0, 150, 136, 0.05) !important;
  border: 1px solid rgba(0, 150, 136, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category3:hover {
  background-color: #4db6ac !important;
  color: #fff !important;
}
.card-category3:hover > .card-category-icon3 {
  color: #fff !important;
}

.card-category4 {
  background-color: rgba(0, 150, 136, 0.05) !important;
  border: 1px solid rgba(0, 150, 136, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category4:hover {
  background-color: #81c784 !important;
  color: #fff !important;
}
.card-category4:hover > .card-category-icon4 {
  color: #fff !important;
}

.card-category5 {
  background-color: rgba(244, 67, 54, 0.06) !important;
  border: 1px solid rgba(244, 67, 54, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category5:hover > .card-category-icon5 {
  color: #fff !important;
}

.card-category5:hover {
  background-color: #ef5350 !important;
  color: #fff !important;
}
.card-category6 {
  background-color: rgba(96, 125, 139, 0.1) !important;
  border: 1px solid rgba(96, 125, 139, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category6:hover {
  background-color: #8d6e63 !important;
  color: #fff !important;
}
.card-category6:hover > .card-category-icon6 {
  color: #fff !important;
}

.card-category7 {
  background-color: rgba(233, 30, 99, 0.1) !important;
  border: 1px solid rgba(255, 235, 59, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category7:hover {
  background-color: #ec407a !important;
  color: #fff !important;
}
.card-category7:hover > .card-category-icon7 {
  color: #fff !important;
}

.card-category8 {
  background-color: rgba(156, 39, 176, 0.1) !important;
  border: 1px solid rgba(156, 39, 176, 0.2) !important;
  cursor: pointer !important;
  color: rgb(34, 34, 34);
}
.card-category8:hover {
  background-color: #7e57c2 !important;
  color: #fff !important;
}
.card-category8:hover > .card-category-icon8 {
  color: #fff !important;
}

.feature-card {
  background-color: rgb(248, 248, 252) !important;
  cursor: pointer !important;
  border: 1px solid rgba(156, 39, 176, 0.2) !important;
}
/***/
.gallery {
  display: grid;
  gap: 5px;
  grid-template-columns: auto 0 auto;
  place-items: center;
}
.gallery > img {
  width: 220px; /* control the size */
  aspect-ratio: 1;
  object-fit: cover;
  transition: 0.35s;
  filter: grayscale(0%);
  cursor: pointer;
}
@media only screen and (max-width: 470px) {
  .gallery {
    display: grid;
    gap: 1px;
    grid-template-columns: auto 0 auto;
    place-items: center;
  }
  .gallery > img {
    width: 160px; /* control the size */
    aspect-ratio: 1;
    object-fit: cover;
    transition: 0.35s;
    filter: grayscale(0%);
    cursor: pointer;
  }
}
.gallery > img:hover {
  filter: grayscale(0%);
}

.gallery > img:nth-child(1) {
  clip-path: polygon(0 0, 50% 0, 100% 100%, 0 100%);
}
.gallery > img:nth-child(2) {
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.gallery > img:nth-child(3) {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
}
.gallery > img:nth-child(4) {
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
}
.gallery > img:nth-child(5) {
  clip-path: polygon(50% 0, 100% 100%, 0% 100%);
}
.gallery > img:nth-child(6) {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
}

.background-patter1 {
  background: linear-gradient(
      135deg,
      #708090 21px,
      #d9ecff 22px,
      #d9ecff 24px,
      transparent 24px,
      transparent 67px,
      #d9ecff 67px,
      #d9ecff 69px,
      transparent 69px
    ),
    linear-gradient(
        225deg,
        #708090 21px,
        #d9ecff 22px,
        #d9ecff 24px,
        transparent 24px,
        transparent 67px,
        #d9ecff 67px,
        #d9ecff 69px,
        transparent 69px
      )
      0 64px;
  background-color: #708090;
  background-size: 64px 128px;
}

.background-pattern2 {
  background-color: #0b042e;
  background-image: radial-gradient(
      white,
      rgba(49, 25, 116, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(white, rgba(42, 30, 90, 0.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 40px),
    radial-gradient(
      rgba(65, 13, 107, 0.4),
      rgba(255, 255, 255, 0.1) 2px,
      transparent 30px
    );
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.background-pattern3 {
  background-color: hsl(255, 66%, 18%);
  background-image: repeating-linear-gradient(
      transparent,
      transparent 50px,
      rgba(0, 0, 0, 0.4) 50px,
      rgba(0, 0, 0, 0.4) 53px,
      transparent 53px,
      transparent 63px,
      rgba(0, 0, 0, 0.4) 63px,
      rgba(0, 0, 0, 0.4) 66px,
      transparent 66px,
      transparent 116px,
      rgba(0, 0, 0, 0.5) 116px,
      rgba(0, 0, 0, 0.5) 166px,
      rgba(255, 255, 255, 0.2) 166px,
      rgba(255, 255, 255, 0.2) 169px,
      rgba(0, 0, 0, 0.5) 169px,
      rgba(0, 0, 0, 0.5) 179px,
      rgba(255, 255, 255, 0.2) 179px,
      rgba(255, 255, 255, 0.2) 182px,
      rgba(0, 0, 0, 0.5) 182px,
      rgba(0, 0, 0, 0.5) 232px,
      transparent 232px
    ),
    repeating-linear-gradient(
      270deg,
      transparent,
      transparent 50px,
      rgba(0, 0, 0, 0.4) 50px,
      rgba(0, 0, 0, 0.4) 53px,
      transparent 53px,
      transparent 63px,
      rgba(0, 0, 0, 0.4) 63px,
      rgba(0, 0, 0, 0.4) 66px,
      transparent 66px,
      transparent 116px,
      rgba(0, 0, 0, 0.5) 116px,
      rgba(0, 0, 0, 0.5) 166px,
      rgba(255, 255, 255, 0.2) 166px,
      rgba(255, 255, 255, 0.2) 169px,
      rgba(0, 0, 0, 0.5) 169px,
      rgba(0, 0, 0, 0.5) 179px,
      rgba(255, 255, 255, 0.2) 179px,
      rgba(255, 255, 255, 0.2) 182px,
      rgba(0, 0, 0, 0.5) 182px,
      rgba(0, 0, 0, 0.5) 232px,
      transparent 232px
    ),
    repeating-linear-gradient(
      125deg,
      transparent,
      transparent 2px,
      rgba(0, 0, 0, 0.2) 2px,
      rgba(0, 0, 0, 0.2) 3px,
      transparent 3px,
      transparent 5px,
      rgba(0, 0, 0, 0.2) 5px
    );
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container .card {
  position: relative;
  width: 280px;
  height: 350px;
  margin: 3px;
}

.container .card:nth-of-type(2) {
  transform: rotate(5deg);
}

.container .card .image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  transform: translate(10px, 10px);
}

.container .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 255px;
  height: 325px;
  border: 25px solid #fff;
  z-index: 1;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 3px 3px 15px #bdbdbd, -3px -3px 15px #ffffff;
  background: transparent;
}

.container .card:nth-of-type(1)::before {
  filter: url(#wavy1);
}

.container .card:nth-of-type(2)::before {
  filter: url(#wavy2);
}

.container .card:nth-of-type(3)::before {
  filter: url(#wavy3);
}

.image1 {
  background-image: url("https://res.cloudinary.com/dswp5qfpm/image/upload/v1597476074/animal/rabbit_vq9gli.jpg");
}

.image2 {
  background-image: url("../images/18.jpg");
}

.image3 {
  background-image: url("../images/1.jpg");
}

svg {
  width: 0;
  height: 0;
}

.container .card .info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 30px 30px 30px;
  transform: translate(10px, 10px);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 1.4;
  font-size: 20px;
  opacity: 0;
  transition-duration: 1s;
}

.container .card:hover .info {
  opacity: 1;
}

.text-effect {
  background-image: linear-gradient(
    to right,
    #875c41 0,
    #cb9b51 22%,
    #cb9b51 45%,
    #cb9b51 50%,
    #cb9b51 85%,
    #cb9b51 78%,
    #462523 100%
  ) !important;
  color: transparent !important;
  -webkit-background-clip: text !important;
}

.footer-background {
  background: -webkit-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -webkit-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: -o-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -o-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: -moz-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -moz-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
}

.bg-aurora {
  color: #fff !important;
  background: -webkit-linear-gradient(70deg, #0c052e 44%, rgba(0, 0, 0, 0) 80%),
    url("../images/22.jpg") !important;
  background: -o-linear-gradient(40deg, #0c052e 44%, rgba(0, 0, 0, 0) 80%),
    url("../images/22.jpg") !important;
  background: -moz-linear-gradient(
      70deg,
      #0c052e 40%,
      rgba(206, 255, 29, 0) 80%
    ),
    url("../images/22.jpg") !important;
  background: linear-gradient(70deg, #0c052e 44%, rgba(0, 0, 0, 0) 80%),
    url("../images/22.jpg") !important;
  background-size: cover !important;
  background-position: 60% 21% !important;
  -webkit-filter: saturate(2) !important;
  filter: saturate(2) !important;
}

.fancy-border {
  border: 25px solid #b88846;
  border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23B88846' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23B88846'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")
    25;
}

.underlined {
  /*   background: red; */
  position: relative;
}

.underline-mask:after {
  content: "";
  position: absolute;
  top: 95%;
  width: 150%;
  aspect-ratio: 3 / 1;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  border: 6px solid hsl(280 80% 50%);
  /* Use a conic gradient mask to hide and show the bits you want */
  --spread: 140deg;
  --start: 290deg;
  mask: conic-gradient(
    from var(--start),
    white 0 var(--spread),
    transparent var(--spread)
  );
}

.underline-overflow {
  display: inline-block;
  /* A little extra padding and overflow hidden   */
  overflow: hidden;
  padding-bottom: clamp(1rem, 2vmin, 2rem);
}
.underline-overflow:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 150%;
  aspect-ratio: 2.5 / 1;
  left: 50%;
  transform: translate(-50%, -10%);
  border-radius: 50%;
  border: 6px solid hsl(10 80% 50%);
}

.underline-clip:after {
  content: "";
  position: absolute;
  top: 95%;
  width: 150%;
  aspect-ratio: 3 / 1;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  border: 6px solid hsl(130 80% 50%);
  /* Use a clip-path to hide and show the bits you want */
  clip-path: polygon(0 0, 50% 50%, 100% 0);
}
